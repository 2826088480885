import { useQuery } from 'react-query';
import { minutesToMilliseconds } from 'date-fns';
import { PromiseType } from 'utility-types';

import { userApi } from '../../api';
import { userQueryKeys } from '../../consts';

export const dexcomConnectionStatusQueryFactory =
  (dependancies: { userApi: typeof userApi }) =>
  ({ enabled }: { enabled?: boolean } = {}) => {
    const { userApi } = dependancies;

    return useQuery({
      queryKey: userQueryKeys.dexcomConnectionStatus(),
      queryFn: () => userApi.getDexcomConnectionStatus(),

      staleTime: minutesToMilliseconds(10),
      enabled: enabled ?? true,
      select: mapToDexcomConnectionStatus,
    });
  };

export const useDexcomConnectionStatusQuery =
  dexcomConnectionStatusQueryFactory({
    userApi,
  });

const mapToDexcomConnectionStatus = (
  dto: PromiseType<ReturnType<typeof userApi.getDexcomConnectionStatus>>
) => ({
  connectionStatus: dto.credentials_status,
  lastSyncTimestamp: dto.last_sync_timestamp
    ? new Date(dto.last_sync_timestamp)
    : null,
  lastSyncFailTimestamp: dto.last_sync_fail_timestamp
    ? new Date(dto.last_sync_fail_timestamp)
    : null,
});
