import { PropsWithChildren, useContext, createContext } from 'react';

export const routes = {
  appDashboardRoute: `/app-dashboard`,
  oauthLanding: '/oauth-landing',
  authorizedSupportAccessLanding: '/admin-access',
  termsAndConditions: `/terms-and-conditions`,
  privacyPolicy: `/privacy-policy`,
  cookiePolicy: `/cookie-policy`,
  root: '/',
  logout: '/logout',
  sessionExpired: '/session-expired',
  login: '/login',
  user: {
    index: '/my',
    report: '/my/report',
    readings: '/my/readings',
    graph: '/my/graph',
    events: '/my/events',
    pdfReport: '/my/pdf-report',
    detailedReadings: {
      index: () => `/my/detailed-readings`,
      readingNameDetails: (slug = ':slug') => `/my/detailed-readings/${slug}`,
      macrosDetails: () => '/my/detailed-readings/macros',
      bloodPressureDetails: () => '/my/detailed-readings/blood-pressure',
    },
    practices: {
      index: '/my/shared-data',
      /**
       * @deprecated Use `routes.user.practice.index` instead
       */
      oldIndex: '/my/practices',
      practice: {
        index: (practiceId = ':practiceId') => `/my/shared-data/${practiceId}`,
        report: {
          index: (practiceId = ':practiceId') =>
            `/my/shared-data/${practiceId}/report`,
          timeInRange: (practiceId = ':practiceId') =>
            `/my/shared-data/${practiceId}/report/time-in-range`,
          lastReadings: (practiceId = ':practiceId') =>
            `/my/shared-data/${practiceId}/report/last-readings`,
          averages: (practiceId = ':practiceId') =>
            `/my/shared-data/${practiceId}/report/averages`,
        },
        overview: (practiceId = ':practiceId') =>
          `/my/shared-data/${practiceId}/overview`,
        patient: {
          index: (practiceId = ':practiceId', userId = ':userId') =>
            `/my/shared-data/${practiceId}/patients/${userId}`,
          report: (practiceId = ':practiceId', userId = ':userId') =>
            `/my/shared-data/${practiceId}/patients/${userId}/report`,
          readings: (practiceId = ':practiceId', userId = ':userId') =>
            `/my/shared-data/${practiceId}/patients/${userId}/readings`,
          graph: (practiceId = ':practiceId', userId = ':userId') =>
            `/my/shared-data/${practiceId}/patients/${userId}/graph`,
          events: (practiceId = ':practiceId', userId = ':userId') =>
            `/my/shared-data/${practiceId}/patients/${userId}/events`,
          detailedReadings: {
            index: (practiceId = ':practiceId', userId = ':userId') =>
              `/my/shared-data/${practiceId}/patients/${userId}/detailed-readings`,
            readingNameDetails: (
              practiceId = ':practiceId',
              userId = ':userId',
              slug = ':slug'
            ) =>
              `/my/shared-data/${practiceId}/patients/${userId}/detailed-readings/${slug}`,
            macrosDetails: (practiceId = ':practiceId', userId = ':userId') =>
              `/my/shared-data/${practiceId}/patients/${userId}/detailed-readings/macros`,
            bloodPressureDetails: (
              practiceId = ':practiceId',
              userId = ':userId'
            ) =>
              `/my/shared-data/${practiceId}/patients/${userId}/detailed-readings/blood-pressure`,
          },
        },
      },
    },
    settings: {
      index: '/my/settings',
      personalData: '/my/settings/personal-data',
      privacy: '/my/settings/privacy',
      ranges: '/my/settings/ranges',
      advanced: '/my/settings/advanced',
    },
    integrations: {
      index: '/my/integrations',
      partnerDetails: (partnerId = ':partnerId') =>
        `/my/integrations/${partnerId}`,
    },
    practiceDirectory: {
      // disabled for v1.29 release
      // index: '/my/practice-directory',
      // details: (practiceId = ':practiceId') =>
      //   `/my/practice-directory/${practiceId}/details`,
      index: '/not-found',
      details: (_practiceId = ':practiceId') => `/not-found`,
    },
    supplies: {
      index: '/my/supplies',
      store: '/my/supplies/store',
    },
  },
  hcp: {
    index: '/hcp',
    practices: {
      index: '/hcp/practices',
      create: '/hcp/practices/create',
      practice: {
        index: (practiceId = ':practiceId') => `/hcp/practices/${practiceId}`,
        report: {
          index: (practiceId = ':practiceId') =>
            `/hcp/practices/${practiceId}/report`,
          timeInRange: (practiceId = ':practiceId') =>
            `/hcp/practices/${practiceId}/report/time-in-range`,
          lastReadings: (practiceId = ':practiceId') =>
            `/hcp/practices/${practiceId}/report/last-readings`,
          averages: (practiceId = ':practiceId') =>
            `/hcp/practices/${practiceId}/report/averages`,
        },
        settings: {
          index: (practiceId = ':practiceId') =>
            `/hcp/practices/${practiceId}/settings`,
          practiceDetails: (practiceId = ':practiceId') =>
            `/hcp/practices/${practiceId}/settings/practice-details`,
          practiceData: (practiceId = ':practiceId') =>
            `/hcp/practices/${practiceId}/settings/practice-data`,
          patientsGoals: (practiceId = ':practiceId') =>
            `/hcp/practices/${practiceId}/settings/patients-goals`,
          practiceGoals: (practiceId = ':practiceId') =>
            `/hcp/practices/${practiceId}/settings/practice-goals`,
          reportSettings: (practiceId = ':practiceId') =>
            `/hcp/practices/${practiceId}/settings/report-settings`,
          advanced: (practiceId = ':practiceId') =>
            `/hcp/practices/${practiceId}/settings/advanced`,
          hcpManagement: (practiceId = ':practiceId') =>
            `/hcp/practices/${practiceId}/settings/admin-roles`,
          rangesDescriptions: (practiceId = ':practiceId') =>
            `/hcp/practices/${practiceId}/settings/ranges-descriptions`,
          affiliateLink: (practiceId = ':practiceId') =>
            `/hcp/practices/${practiceId}/settings/affiliate-link`,
          predefinedTags: (practiceId = ':practiceId') =>
            `/hcp/practices/${practiceId}/settings/predefined-tags`,
        },
        patient: {
          index: (practiceId = ':practiceId', patientId = ':userId') =>
            `/hcp/practices/${practiceId}/patients/${patientId}`,
          report: (practiceId = ':practiceId', patientId = ':userId') =>
            `/hcp/practices/${practiceId}/patients/${patientId}/report`,
          readings: (practiceId = ':practiceId', patientId = ':userId') =>
            `/hcp/practices/${practiceId}/patients/${patientId}/readings`,
          graph: (practiceId = ':practiceId', patientId = ':userId') =>
            `/hcp/practices/${practiceId}/patients/${patientId}/graph`,
          events: (practiceId = ':practiceId', patientId = ':userId') =>
            `/hcp/practices/${practiceId}/patients/${patientId}/events`,
          pdfReport: (practiceId = ':practiceId', patientId = ':userId') =>
            `/hcp/practices/${practiceId}/patients/${patientId}/pdf-report`,
          detailedReadings: {
            index: (practiceId = ':practiceId', userId = ':userId') =>
              `/hcp/practices/${practiceId}/patients/${userId}/detailed-readings`,
            readingNameDetails: (
              practiceId = ':practiceId',
              userId = ':userId',
              slug = ':slug'
            ) =>
              `/hcp/practices/${practiceId}/patients/${userId}/detailed-readings/${slug}`,
            macrosDetails: (practiceId = ':practiceId', userId = ':userId') =>
              `/hcp/practices/${practiceId}/patients/${userId}/detailed-readings/macros`,
            bloodPressureDetails: (
              practiceId = ':practiceId',
              userId = ':userId'
            ) =>
              `/hcp/practices/${practiceId}/patients/${userId}/detailed-readings/blood-pressure`,
          },
          details: (practiceId = ':practiceId', patientId = ':userId') =>
            `/hcp/practices/${practiceId}/patients/${patientId}/details`,
        },
      },
    },
  },
  chat: {
    index: '/chat',
    practice: (practiceId = ':practiceId') => `/chat/${practiceId}`,
  },
  notFound: '/not-found',
  legacy: {
    practice: {
      patient: {
        index: (practiceId = ':practiceId', patientId = ':userId') =>
          `/hcp/my-practice/${practiceId}/${patientId}`,
      },
    },
    hcpPracticeReportTimeInRange: `/hcp/practices/:practiceId/reports/time-in-range`,
    hcpPracticeReportLastReadings: `/hcp/practices/:practiceId/reports/average-values`,
    dashboard: '/my/dashboard',
  },
};

export const RoutesContext = createContext(routes);
export const useRoutes = () => useContext(RoutesContext);

export const RoutesProvider = ({
  children,
  value,
}: PropsWithChildren<{ value: typeof routes }>) => (
  <RoutesContext.Provider value={value}>{children}</RoutesContext.Provider>
);
