import { ReadingName } from 'domain/domain.models';
import { Unit } from 'models';

import { NullableRanges, RangesDto } from '../../types/ranges';

export const mapToRanges = (dto: RangesDto): NullableRanges => ({
  [ReadingName.GKI_BLOOD]: {
    [Unit.NO_UNIT]: [dto.gki_min, dto.gki_max],
  },
  [ReadingName.DBR_BLOOD]: {
    [Unit.NO_UNIT]: [dto.dbr_min, dto.dbr_max],
  },
  [ReadingName.KETONE_BLOOD]: {
    [Unit.MMOLL]: [dto.ketones_min, dto.ketones_max],
  },
  [ReadingName.KETONE_BREATH_ACE]: {
    [Unit.ACE]: [dto.ketones_breath_ace_min, dto.ketones_breath_ace_max],
  },
  [ReadingName.KETONE_BREATH_PPM]: {
    [Unit.PPM]: [dto.ketones_breath_ppm_min, dto.ketones_breath_ppm_max],
  },
  [ReadingName.KETONE_URINE]: {
    [Unit.MMOLL]: [dto.ketones_urine_mmoll_min, dto.ketones_urine_mmoll_max],
  },
  [ReadingName.GLUCOSE_BLOOD]: {
    [Unit.MMOLL]: [dto.glucose_mmoll_min, dto.glucose_mmoll_max],
    [Unit.MGDL]: [dto.glucose_mgdl_min, dto.glucose_mgdl_max],
  },
  [ReadingName.GLUCOSE_INTERSTITIAL]: {
    [Unit.MMOLL]: [dto.glucose_mmoll_min, dto.glucose_mmoll_max],
    [Unit.MGDL]: [dto.glucose_mgdl_min, dto.glucose_mgdl_max],
  },
  [ReadingName.WAIST_MEASUREMENT]: {
    [Unit.CM]: [dto.waist_cm_min, dto.waist_cm_max],
    [Unit.INCH]: [dto.waist_inch_min, dto.waist_inch_max],
  },
  [ReadingName.WEIGHT_MEASUREMENT]: {
    [Unit.KG]: [dto.weight_kg_min, dto.weight_kg_max],
    [Unit.LBS]: [dto.weight_lbs_min, dto.weight_lbs_max],
  },
  [ReadingName.PROTEIN_INGESTION]: {
    [Unit.GRAMS]: [dto.protein_g_min, dto.protein_g_max],
    [Unit.KCAL]: [dto.protein_kcal_min, dto.protein_kcal_max],
  },
  [ReadingName.FAT_INGESTION]: {
    [Unit.GRAMS]: [dto.fat_g_min, dto.fat_g_max],
    [Unit.KCAL]: [dto.fat_kcal_min, dto.fat_kcal_max],
  },
  [ReadingName.CARBS_INGESTION]: {
    [Unit.GRAMS]: [dto.carbohydrates_g_min, dto.carbohydrates_g_max],
    [Unit.KCAL]: [dto.carbohydrates_kcal_min, dto.carbohydrates_kcal_max],
  },
  [ReadingName.CALORIES_INGESTION]: {
    [Unit.KCAL]: [dto.calories_min, dto.calories_max],
  },
  [ReadingName.VITAMIN_D_INGESTION]: {
    [Unit.IU]: [dto.vitamin_d_iu_min, dto.vitamin_d_iu_max],
    [Unit.MCG]: [dto.vitamin_d_mcg_min, dto.vitamin_d_mcg_max],
  },
  [ReadingName.MAGNESIUM_INGESTION]: {
    [Unit.MG]: [dto.magnesium_min, dto.magnesium_max],
  },
  [ReadingName.POTASSIUM_INGESTION]: {
    [Unit.MG]: [dto.potassium_min, dto.potassium_max],
  },
  [ReadingName.OMEGA_3_INGESTION]: {
    [Unit.MG]: [dto.omega_3_min, dto.omega_3_max],
  },
  [ReadingName.OMEGA_3_EPA_INGESTION]: {
    [Unit.MG]: [dto.omega_3_epa_min, dto.omega_3_epa_max],
  },
  [ReadingName.OMEGA_3_DHA_INGESTION]: {
    [Unit.MG]: [dto.omega_3_dha_min, dto.omega_3_dha_max],
  },
  [ReadingName.ZINC_INGESTION]: {
    [Unit.MG]: [dto.zinc_min, dto.zinc_max],
  },
  [ReadingName.VITAMIN_B12_INGESTION]: {
    [Unit.MCG]: [dto.vitamin_b12_min, dto.vitamin_b12_max],
  },
  [ReadingName.SELENIUM_INGESTION]: {
    [Unit.MCG]: [dto.selenium_min, dto.selenium_max],
  },
  [ReadingName.BMI_MEASUREMENT]: {
    [Unit.KG_M2]: [dto.bmi_min, dto.bmi_max],
  },
  [ReadingName.HEART_RATE_MEASUREMENT]: {
    [Unit.BPM]: [dto.hr_min, dto.hr_max],
  },
  [ReadingName.HEART_RATE_VARIABILITY_MEASUREMENT]: {
    [Unit.MS]: [dto.hrv_min, dto.hrv_max],
  },
  [ReadingName.BLOOD_PRESSURE_SYSTOLIC_MEASUREMENT]: {
    [Unit.MMHG]: [
      dto.blood_pressure_systolic_min,
      dto.blood_pressure_systolic_max,
    ],
  },
  [ReadingName.BLOOD_PRESSURE_DIASTOLIC_MEASUREMENT]: {
    [Unit.MMHG]: [
      dto.blood_pressure_diastolic_min,
      dto.blood_pressure_diastolic_max,
    ],
  },
});
