export enum Unit {
  MMHG = 'mmHg',
  MMOLL = 'mmoll',
  MGDL = 'mgdl',
  NO_UNIT = '',
  ACE = 'ACE',
  PPM = 'PPM',
  GRAMS = 'g',
  KCAL = 'kcal',
  CM = 'cm',
  INCH = 'inch',
  KG = 'kg',
  LBS = 'lbs',
  IU = 'iu',
  MCG = 'mcg',
  MG = 'mg',
  BPM = 'bpm',
  MS = 'ms',
  KG_M2 = 'kg/m2',
}

export const getUnitsList = () => Object.values(Unit);
