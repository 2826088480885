import i18next from 'i18next';

import { Unit } from 'models';

export const getFormattedUnit = (unit: Unit): string => {
  switch (unit) {
    case Unit.MGDL:
      return i18next.t('mg/dL');
    case Unit.MMOLL:
      return i18next.t('mmol/L');
    case Unit.PPM:
      return i18next.t('PPM');
    case Unit.ACE:
      return i18next.t('ACE');
    case Unit.GRAMS:
      return i18next.t('g');
    case Unit.KCAL:
      return i18next.t('kcal');
    case Unit.CM:
      return i18next.t('cm');
    case Unit.INCH:
      return i18next.t('in');
    case Unit.LBS:
      return i18next.t('lbs');
    case Unit.KG:
      return i18next.t('kg');
    case Unit.NO_UNIT:
      return '';
    case Unit.IU:
      return i18next.t('iu');
    case Unit.MCG:
      return i18next.t('mcg');
    case Unit.MG:
      return i18next.t('mg');
    case Unit.BPM:
      return i18next.t('bpm');
    case Unit.MS:
      return i18next.t('ms');
    case Unit.MMHG:
      return i18next.t('mmHg');
    case Unit.KG_M2:
      return i18next.t('kg/m2');
  }
};
