import { UnionToIntersection } from 'utility-types';

import { ReadingTypeColor } from 'consts/colors/colors';
import { TailwindColorGroup } from 'consts/colors/colors.types';
import { ReadingSampleType, ReadingType, Unit } from 'models';
import { ReadingCategory } from 'models/reading-category';
import { ReadingEntryType } from 'models/reading-entry-type';
import { DefinedRange, NullableRange } from 'types/utility';

/**
 * ReadingName enum is manually created using `{ReadingType}_{ReadingSampleType}`
 */
export enum ReadingName {
  GLUCOSE_BLOOD = 'glucose__blood',
  GLUCOSE_INTERSTITIAL = 'glucose__interstitial',

  KETONE_BLOOD = 'ketone__blood',
  KETONE_BREATH_ACE = 'ketone__breath_ace',
  KETONE_BREATH_PPM = 'ketone__breath_ppm',
  KETONE_URINE = 'ketone__urine',
  GKI_BLOOD = 'glucose_ketone_index__blood',
  DBR_BLOOD = 'dbr__blood',

  WEIGHT_MEASUREMENT = 'weight__measurement',
  WAIST_MEASUREMENT = 'waist__measurement',

  CARBS_INGESTION = 'carbohydrates__ingestion',
  FAT_INGESTION = 'fat__ingestion',
  PROTEIN_INGESTION = 'protein__ingestion',
  CALORIES_INGESTION = 'calories__ingestion',
  VITAMIN_D_INGESTION = 'vitamin_d__ingestion',
  MAGNESIUM_INGESTION = 'magnesium__ingestion',
  POTASSIUM_INGESTION = 'potassium__ingestion',
  OMEGA_3_INGESTION = 'omega_3__ingestion',
  OMEGA_3_EPA_INGESTION = 'omega_3_epa__ingestion',
  OMEGA_3_DHA_INGESTION = 'omega_3_dha__ingestion',
  ZINC_INGESTION = 'zinc__ingestion',
  VITAMIN_B12_INGESTION = 'vitamin_b12__ingestion',
  SELENIUM_INGESTION = 'selenium__ingestion',
  BMI_MEASUREMENT = 'bmi__measurement',
  HEART_RATE_MEASUREMENT = 'hr__measurement',
  HEART_RATE_VARIABILITY_MEASUREMENT = 'hrv__measurement',
  BLOOD_PRESSURE_SYSTOLIC_MEASUREMENT = 'blood_pressure_systolic__measurement',
  BLOOD_PRESSURE_DIASTOLIC_MEASUREMENT = 'blood_pressure_diastolic__measurement',
}
export const readingNames = Object.values(ReadingName) as ReadingName[];

export const isReadingName = (value: any): value is ReadingName =>
  readingNames.includes(value);

export type GlucoseBloodUnit = Unit.MGDL | Unit.MMOLL;
export type GlucoseInterstitialUnit = Unit.MGDL | Unit.MMOLL;
export type KetoneBloodUnit = Unit.MMOLL;
export type KetoneUrineUnit = Unit.MMOLL;
export type KetoneBreathAceUnit = Unit.ACE;
export type KetoneBreathPpmUnit = Unit.PPM;
export type GkiBloodUnit = Unit.NO_UNIT;
export type DbrBloodUnit = Unit.NO_UNIT;
export type WeightMeasurementUnit = Unit.KG | Unit.LBS;
export type WaistUMeasurementUnit = Unit.CM | Unit.INCH;
export type CarbsIngestionUnit = Unit.GRAMS | Unit.KCAL;
export type FatIngestionUnit = Unit.GRAMS | Unit.KCAL;
export type ProteinIngestionUnit = Unit.GRAMS | Unit.KCAL;
export type CaloriesIngestionUnit = Unit.KCAL;
export type VitaminDIngestionUnit = Unit.IU | Unit.MCG;
export type MagnesiumIngestionUnit = Unit.MG;
export type PotassiumIngestionUnit = Unit.MG;
export type Omega3IngestionUnit = Unit.MG;
export type Omega3EpaIngestionUnit = Unit.MG;
export type Omega3DhaIngestionUnit = Unit.MG;
export type ZincIngestionUnit = Unit.MG;
export type VitaminB12IngestionUnit = Unit.MCG;
export type SeleniumIngestionUnit = Unit.MCG;
export type BmiMeasurementUnit = Unit.KG_M2;
export type HeartRateMeasurementUnit = Unit.BPM;
export type HeartRateVariabilityMeasurementUnit = Unit.MS;
export type BloodPressureSystolicMeasurementUnit = Unit.MMHG;
export type BloodPressureDiastolicMeasurementUnit = Unit.MMHG;

export type GkrReadingName = ReadingName.GKI_BLOOD | ReadingName.DBR_BLOOD;

export type ReadingNameToPreferredUnitMap = {
  [ReadingName.GLUCOSE_BLOOD]: GlucoseBloodUnit;
  [ReadingName.GLUCOSE_INTERSTITIAL]: GlucoseBloodUnit;
  [ReadingName.WEIGHT_MEASUREMENT]: WeightMeasurementUnit;
  [ReadingName.WAIST_MEASUREMENT]: WaistUMeasurementUnit;
  [ReadingName.PROTEIN_INGESTION]: ProteinIngestionUnit;
  [ReadingName.FAT_INGESTION]: FatIngestionUnit;
  [ReadingName.CARBS_INGESTION]: CarbsIngestionUnit;
  [ReadingName.VITAMIN_D_INGESTION]: VitaminDIngestionUnit;
};
export type ReadingNameWithPreferredUnit = keyof ReadingNameToPreferredUnitMap;
export type ReadingNameToNullablePreferredUnitMap = {
  [Key in ReadingNameWithPreferredUnit]:
    | ReadingNameToPreferredUnitMap[Key]
    | null;
};

export type ReadingNameToUnitMap = ReadingNameToPreferredUnitMap & {
  [ReadingName.KETONE_BLOOD]: KetoneBloodUnit;
  [ReadingName.KETONE_URINE]: KetoneUrineUnit;
  [ReadingName.KETONE_BREATH_ACE]: KetoneBreathAceUnit;
  [ReadingName.KETONE_BREATH_PPM]: KetoneBreathPpmUnit;
  [ReadingName.GKI_BLOOD]: GkiBloodUnit;
  [ReadingName.DBR_BLOOD]: DbrBloodUnit;
  [ReadingName.CALORIES_INGESTION]: CaloriesIngestionUnit;
  [ReadingName.MAGNESIUM_INGESTION]: MagnesiumIngestionUnit;
  [ReadingName.POTASSIUM_INGESTION]: PotassiumIngestionUnit;
  [ReadingName.OMEGA_3_INGESTION]: Omega3IngestionUnit;
  [ReadingName.OMEGA_3_EPA_INGESTION]: Omega3EpaIngestionUnit;
  [ReadingName.OMEGA_3_DHA_INGESTION]: Omega3DhaIngestionUnit;
  [ReadingName.ZINC_INGESTION]: ZincIngestionUnit;
  [ReadingName.VITAMIN_B12_INGESTION]: VitaminB12IngestionUnit;
  [ReadingName.SELENIUM_INGESTION]: SeleniumIngestionUnit;
  [ReadingName.BMI_MEASUREMENT]: BmiMeasurementUnit;
  [ReadingName.HEART_RATE_MEASUREMENT]: HeartRateMeasurementUnit;
  [ReadingName.HEART_RATE_VARIABILITY_MEASUREMENT]: HeartRateVariabilityMeasurementUnit;
  [ReadingName.BLOOD_PRESSURE_SYSTOLIC_MEASUREMENT]: BloodPressureSystolicMeasurementUnit;
  [ReadingName.BLOOD_PRESSURE_DIASTOLIC_MEASUREMENT]: BloodPressureDiastolicMeasurementUnit;
};

type ReadingNameToReadingTypeMap = {
  [ReadingName.GLUCOSE_BLOOD]: ReadingType.GLUCOSE;
  [ReadingName.GLUCOSE_INTERSTITIAL]: ReadingType.GLUCOSE;
  [ReadingName.WEIGHT_MEASUREMENT]: ReadingType.WEIGHT;
  [ReadingName.WAIST_MEASUREMENT]: ReadingType.WAIST;
  [ReadingName.PROTEIN_INGESTION]: ReadingType.PROTEIN;
  [ReadingName.FAT_INGESTION]: ReadingType.FAT;
  [ReadingName.CARBS_INGESTION]: ReadingType.CARBS;
  [ReadingName.VITAMIN_D_INGESTION]: ReadingType.VITAMIN_D;
  [ReadingName.KETONE_BLOOD]: ReadingType.KETONE;
  [ReadingName.KETONE_BLOOD]: ReadingType.KETONE;
  [ReadingName.KETONE_URINE]: ReadingType.KETONE;
  [ReadingName.KETONE_BREATH_ACE]: ReadingType.KETONE;
  [ReadingName.KETONE_BREATH_PPM]: ReadingType.KETONE;
  [ReadingName.GKI_BLOOD]: ReadingType.GLUCOSE_KETONE_INDEX;
  [ReadingName.DBR_BLOOD]: ReadingType.DBR;
  [ReadingName.CALORIES_INGESTION]: ReadingType.CALORIES;
  [ReadingName.MAGNESIUM_INGESTION]: ReadingType.MAGNESIUM;
  [ReadingName.POTASSIUM_INGESTION]: ReadingType.POTASSIUM;
  [ReadingName.OMEGA_3_INGESTION]: ReadingType.OMEGA_3;
  [ReadingName.OMEGA_3_EPA_INGESTION]: ReadingType.OMEGA_3_EPA;
  [ReadingName.OMEGA_3_DHA_INGESTION]: ReadingType.OMEGA_3_DHA;
  [ReadingName.ZINC_INGESTION]: ReadingType.ZINC;
  [ReadingName.VITAMIN_B12_INGESTION]: ReadingType.VITAMIN_B12;
  [ReadingName.SELENIUM_INGESTION]: ReadingType.SELENIUM;
  [ReadingName.BMI_MEASUREMENT]: ReadingType.BMI;
  [ReadingName.HEART_RATE_MEASUREMENT]: ReadingType.HEART_RATE;
  [ReadingName.HEART_RATE_VARIABILITY_MEASUREMENT]: ReadingType.HEART_RATE_VARIABILITY;
  [ReadingName.BLOOD_PRESSURE_SYSTOLIC_MEASUREMENT]: ReadingType.BLOOD_PRESSURE_SYSTOLIC;
  [ReadingName.BLOOD_PRESSURE_DIASTOLIC_MEASUREMENT]: ReadingType.BLOOD_PRESSURE_DIASTOLIC;
};

export type UnitForReadingName<T extends ReadingName> = ReadingNameToUnitMap[T];

type Range = NullableRange | DefinedRange;
export type ReadingRanges<T extends ReadingName, TRange extends Range> = Record<
  ReadingNameToUnitMap[T],
  TRange
>;
export type DefinedReadingRanges<T extends ReadingName> = ReadingRanges<
  T,
  DefinedRange
>;
export type RangesPerReadingName = {
  [TReadingName in ReadingName]: ReadingRanges<TReadingName, Range>;
};
export type DefinedRangesPerReadingName = {
  [TReadingName in ReadingName]: ReadingRanges<TReadingName, DefinedRange>;
};
export type NullableRangesMap = {
  [TReadingName in ReadingName]: ReadingRanges<TReadingName, NullableRange>;
};

type ReadingNameRangeColorPallete = {
  belowLowerTresholdRange: string;
  withinLowerTresholdRange: string;
  inRange: string;
  withinUpperTresholdRange: string;
  aboveUpperTresholdRange: string;
};

export type DomainConfig<T extends ReadingName = ReadingName> = {
  readingName: T;
  readingType: ReadingNameToReadingTypeMap[T];
  readingSampleType: ReadingSampleType;
  category: ReadingCategory;
  entryTypes: NonEmptyArray<ReadingEntryType>;
  color: ReadingTypeColor;
  colorPalette: TailwindColorGroup;
  colorPaletteDark: TailwindColorGroup;
  units: NonEmptyArray<UnitForReadingName<T>>;
  defaultRanges: DefinedReadingRanges<T>;
  boundaryRanges: DefinedReadingRanges<T>;
  rangeColorPalette: ReadingNameRangeColorPallete;
  label: () => string;
  labelIncludesUnit?: boolean;

  /**
   * Used to access Reading Details page
   */
  slug: string;

  /**
   * Should it be possible to manually add a reading
   */
  canBeAddedManually: boolean;
};

export type DomainConfigs = {
  [T in ReadingName]: DomainConfig<T>;
};

type DomainConfigRangesUnion = DomainConfigs[ReadingName]['defaultRanges'];
export type DomainConfigRangesIntersection =
  UnionToIntersection<DomainConfigRangesUnion>;

export enum KetoneUrineValueType {
  NEGATIVE = 'negative',
  TRACE = 'trace',
  SMALL = 'small',
  MODERATE = 'moderate',
  LARGE = 'large',
  LARGER = 'larger',
}

type ContinuousReadingType =
  | ReadingType.HEART_RATE
  | ReadingType.HEART_RATE_VARIABILITY;
type ContinuousReadingName =
  | ReadingName.HEART_RATE_MEASUREMENT
  | ReadingName.HEART_RATE_VARIABILITY_MEASUREMENT;

export type ReadingTypeWithoutContinuousEntryType = Exclude<
  ReadingType,
  ContinuousReadingType
>;

export type ReadingNameWithoutContinuousEntryType = Exclude<
  ReadingName,
  ContinuousReadingName
>;

export enum CustomReadingName {
  MACROS = 'macros',
  BLOOD_PRESSURE = 'blood-pressure',
}

export type MacroReadingName =
  | ReadingName.FAT_INGESTION
  | ReadingName.CARBS_INGESTION
  | ReadingName.PROTEIN_INGESTION;

const customReadingNames = Object.values(CustomReadingName);

/**
 * Type used in lists of readings, where aside of standard reading names, there
 * need to be displayed other custom items.
 */
export type ReadingItem = `${ReadingName}` | `${CustomReadingName}`;

/**
 * Config used to display reading names lists
 */
export type ReadingItemConfig = {
  readingItem: ReadingItem;
  slug: string;
  category: ReadingCategory;
  label: () => string;
  canBeAddedManually: boolean;
};

export const isReadingItem = (value: any): value is ReadingItem =>
  [...readingNames, ...customReadingNames].includes(value);
