export enum ReadingType {
  BLOOD_PRESSURE_SYSTOLIC = 'blood_pressure_systolic',
  BLOOD_PRESSURE_DIASTOLIC = 'blood_pressure_diastolic',
  KETONE = 'ketone',
  GLUCOSE = 'glucose',
  GLUCOSE_KETONE_INDEX = 'glucose_ketone_index',
  DBR = 'dbr',
  CARBS = 'carbohydrates',
  FAT = 'fat',
  PROTEIN = 'protein',
  WEIGHT = 'weight',
  CALORIES = 'calories',
  WAIST = 'waist',
  VITAMIN_D = 'vitamin_d',
  MAGNESIUM = 'magnesium',
  POTASSIUM = 'potassium',
  OMEGA_3 = 'omega_3',
  OMEGA_3_EPA = 'omega_3_epa',
  OMEGA_3_DHA = 'omega_3_dha',
  ZINC = 'zinc',
  VITAMIN_B12 = 'vitamin_b12',
  SELENIUM = 'selenium',
  BMI = 'bmi',
  HEART_RATE = 'hr',
  HEART_RATE_VARIABILITY = 'hrv',
}

export const readingTypes = Object.values(ReadingType) as ReadingType[];

export const getReadingTypes = () =>
  (Object.keys(ReadingType) as (keyof typeof ReadingType)[]).map(
    (key) => ReadingType[key]
  );

export type TReadingType = `${ReadingType}`;

export enum ExtraReadingTypes {
  HEMOGLOBIN = 'hemoglobin',
  HEMATOCRIT = 'hematocrit',
}

export const isReadingType = (value: any): value is ReadingType =>
  Object.values(ReadingType).includes(value as ReadingType);

export type ExpandedReadingType = ReadingType | ExtraReadingTypes;

export type MacroReadingType =
  | ReadingType.CARBS
  | ReadingType.PROTEIN
  | ReadingType.FAT;

export type GkrReadingType = ReadingType.GLUCOSE_KETONE_INDEX | ReadingType.DBR;

export const isMacroReadingType = (
  readingType: ReadingType
): readingType is MacroReadingType =>
  [ReadingType.CARBS, ReadingType.FAT, ReadingType.PROTEIN].includes(
    readingType
  );
