/* eslint-disable func-style */

import round from 'lodash/round';

import { ReadingName } from 'domain/domain.models';
import { Unit } from 'models';

type ConvertFn = (v: number) => number;

/**
 * Creates a converter function that accepts either a number or a range.
 */
const convertFactory = (convertFn: ConvertFn) => {
  function convert(range: [number, number]): [number, number];
  function convert(value: number): number;
  function convert(value: any): any {
    if (Array.isArray(value)) {
      return value.map(convertFn);
    }

    return convertFn(value);
  }

  return convert;
};

export const mgdlToMmoll = convertFactory((v) => round(v / 18, 4));
export const mmollToMgdl = convertFactory((v) => round(v * 18, 2));
export const gramsToKcal = convertFactory((v) => round(v * 4, 2));
export const kcalToGrams = convertFactory((v) => round(v / 4, 4));
export const fatGramsToKcal = convertFactory((v) => round(v * 9, 2));
export const fatKcalToGrams = convertFactory((v) => round(v / 9, 4));
export const cmToInch = convertFactory((v) => round(v * 0.393701, 4));
export const inchToCm = convertFactory((v) => round(v / 0.393701, 3));
export const kgToLbs = convertFactory((v) => round(v * 2.20462, 4));
export const lbsToKg = convertFactory((v) => round(v / 2.20462, 3));
export const iuToMcg = convertFactory((v) => round(v / 40, 4));
export const mcgToIu = convertFactory((v) => round(v * 40, 2));
export const gkiToDbr = convertFactory((v) => round(v * 18, 0));
export const dbrToGki = convertFactory((v) => round(v / 18, 1));

/**
 * Specific converter is used when a specific reading needs its own converter,
 * such as Fat.
 *
 * In other cases - for general conversion between units - multiple readings
 * can use the same converter.
 */
const hasSpecificConverter = (readingName?: ReadingName) => {
  switch (readingName) {
    case ReadingName.FAT_INGESTION:
      return true;

    default:
      return false;
  }
};

const key = (from: Unit, to: Unit, readingName?: ReadingName) =>
  hasSpecificConverter(readingName)
    ? `${from}_${to}_${readingName}`
    : `${from}_${to}`;

const converters = {
  [key(Unit.MGDL, Unit.MMOLL)]: mgdlToMmoll,
  [key(Unit.MMOLL, Unit.MGDL)]: mmollToMgdl,

  [key(Unit.GRAMS, Unit.KCAL)]: gramsToKcal,
  [key(Unit.KCAL, Unit.GRAMS)]: kcalToGrams,

  [key(Unit.GRAMS, Unit.KCAL, ReadingName.FAT_INGESTION)]: fatGramsToKcal,
  [key(Unit.KCAL, Unit.GRAMS, ReadingName.FAT_INGESTION)]: fatKcalToGrams,

  [key(Unit.CM, Unit.INCH)]: cmToInch,
  [key(Unit.INCH, Unit.CM)]: inchToCm,

  [key(Unit.KG, Unit.LBS)]: kgToLbs,
  [key(Unit.LBS, Unit.KG)]: lbsToKg,

  [key(Unit.IU, Unit.MCG)]: iuToMcg,
  [key(Unit.MCG, Unit.IU)]: mcgToIu,
};

export const getConverter = (
  unitFrom: Unit,
  unitTo: Unit,
  readingName?: ReadingName
) => {
  const converterKey = key(unitFrom, unitTo, readingName);
  const converter = converters[converterKey];

  if (!converter) {
    let message = `No converter found for ${unitFrom} to ${unitTo}`;
    if (readingName) message += ` for ${readingName}`;
    throw new Error(message);
  }

  return converter;
};
